.benefitsSection {
  background-color: rgba(25, 25, 25, 1);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.container {
  position: relative;
  padding: 80px;
  min-height: 2422px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content {
  position: relative;
  max-width: 1314px;
  width: 100%;
  z-index: 1;
}

.mainTitle {
  color: #fff;
  text-align: center;
  font: 600 50px/1 Inter, sans-serif;
  letter-spacing: -0.25px;
  margin-bottom: 30px;
}

.subtitle {
  color: #fff;
  text-align: center;
  font: 400 26px/1 Poppins, sans-serif;
  letter-spacing: 0.1px;
  margin-bottom: 152px;
}

.featuresGrid {
  display: flex;
  flex-direction: column;
  gap: 81px;
}

.featureContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.imageWrapper {
  width: 275px;
  flex-shrink: 0;
}

.featureImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.featureContent {
  flex: 1;
  color: #fff;
  font-family: Poppins, sans-serif;
}

.featureIcon {
  width: 41px;
  height: 41px;
  object-fit: contain;
}

.featureTitle {
  font-size: 30px;
  font-weight: 600;
  margin: 12px 0;
}

.featureDescription {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.74px;
}

@media (max-width: 991px) {
  .container {
    padding: 20px;
  }

  .mainTitle {
    font-size: 40px;
  }

  .subtitle {
    margin-bottom: 40px;
  }

  .featuresGrid {
    gap: 40px;
  }

  .featureContainer {
    flex-direction: column;
  }

  .imageWrapper {
    width: 100%;
    max-width: 275px;
    margin: 0 auto;
  }
}
