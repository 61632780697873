.featuresSection {
  background: rgba(25, 25, 25, 1);
  display: flex;
  padding: 0 0 85px;
  flex-direction: column;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 1774px;
  width: 100%;
  align-items: flex-end;
  padding: 80px 80px 235px;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.content {
  position: relative;
  display: flex;
  margin-bottom: -47px;
  width: 1240px;
  max-width: 100%;
  flex-direction: column;
}

.heading {
  color: #fff;
  letter-spacing: -0.25px;
  align-self: flex-start;
  font: 500 50px/1 Inter, sans-serif;
}

.description {
  display: flex;
  margin-top: 30px;
  width: 100%;
  padding-left: 59px;
  flex-direction: column;
  font-size: 26px;
}

.subheading {
  color: #fff;
  letter-spacing: 0.1px;
  text-align: center;
  width: 712px;
  font: 600 24px/32px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.featureCard {
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 16px;
  padding: 35px;
}

.featureIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 49px;
  margin: auto 0;
}

.featureContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.featureTitle {
  color: #fff;
  font: 500 26px/1 Montserrat, sans-serif;
  letter-spacing: 0.23px;
}

.featureDescription {
  color: #a1a2a1;
  font: 400 26px/1 Karla, sans-serif;
  letter-spacing: 0.15px;
  margin-top: 13px;
}

.feature1 {
  align-self: center;
  margin-top: 209px;
  width: 700px;
}

.feature2 {
  align-self: flex-end;
  margin-top: 150px;
}

.feature3 {
  align-self: flex-end;
  margin-top: 169px;
}

.feature5 {
  align-self: center;
  margin-top: 169px;
  width: 700px;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 20px 100px;
  }

  .content {
    margin-bottom: 10px;
  }

  .heading {
    max-width: 100%;
    font-size: 40px;
  }

  .description {
    max-width: 100%;
    padding-left: 20px;
  }

  .subheading {
    max-width: 100%;
  }

  .feature1,
  .feature2,
  .feature3,
  .feature5 {
    margin-top: 40px;
    padding: 35px 20px;
  }
}
