.heroSection {
  position: relative;
  width: 100%;
}

.backgroundWrapper {
  width: 100%;
}

.backgroundImage {
  aspect-ratio: 1.58;
  object-fit: contain;
  width: 100%;
  /* max-width: 1681px; */
  width:100%;
}

.contentWrapper {
  position: relative;
  margin-top: -1062px;
  min-height: 1062px;
  display: flex;
  align-items: center;
  gap: 33px;
  padding: 0 72px;
}

.heroContent {
  flex: 1;
  max-width: 967px;
}

.badge {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 21px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 21px;
  width: fit-content;
}

.badgeIcon {
  width: 34px;
  height: 34px;
}

.badgeText {
  color: #fff;
  font: 700 16px/1.3 Poppins, sans-serif;
  letter-spacing: 0.65px;
}

.textContent {
  margin-top: 30px;
  color: #fff;
}

.mainHeading {
  font: 600 60px/73px Inter, sans-serif;
  letter-spacing: -0.25px;
  margin: 0;
}

.subHeading {
  font: 24px/35px Poppins, sans-serif;
  letter-spacing: 0.1px;
  margin-top: 24px;
}

.storeButtons {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.storeButton {
  width: 202px;
  height: auto;
}

.heroImages {
  display: flex;
  gap: 20px;
}

.heroImage {
  aspect-ratio: 0.48;
  object-fit: contain;
}

@media (max-width: 991px) {
  .contentWrapper {
    margin-top: -200px;
    padding: 0 20px;
    flex-direction: column;
  }

  .mainHeading {
    font-size: 40px;
    line-height: 54px;
  }
}
