.footer {
  background: #191919;
  padding: 120px 80px 30px;
  color: #fff;
}

.footerContent {
  max-width: 1474px;
  margin: 0 auto;
}

.brandSection {
  display: flex;
  align-items: center;
  gap: 26px;
  margin-bottom: 16px;
}

.logo {
  width: 86px;
  height: auto;
  object-fit: contain;
}

.brandName {
  font: 400 50px/1.2 Voces, sans-serif;
}

.sectionsContainer {
  display: flex;
  gap: 100px;
  margin-top: 40px;
}

.footerSection {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font: 500 30px/1.5 "TT Commons", sans-serif;
  letter-spacing: 0.28px;
  margin-bottom: 29px;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footerLink {
  color: #fff;
  font: 400 26px/1 "TT Commons", sans-serif;
  letter-spacing: 0.19px;
  text-decoration: none;
}

.contactSection {
  display: flex;
  flex-direction: column;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  gap: 29px;
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 18px;
  color: #fff;
  text-decoration: none;
}

.contactIcon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.contactText {
  font: 400 26px/1 "TT Commons", sans-serif;
  letter-spacing: 0.19px;
}

.divider {
  margin: 157px 0 21px;
}

.dividerImage {
  width: 100%;
  height: 1px;
}

.copyright {
  color: #e2e2e2;
  text-align: center;
  font: 500 18px "TT Commons", sans-serif;
}

@media (max-width: 991px) {
  .footer {
    padding: 100px 20px 0;
  }

  .brandName {
    font-size: 40px;
  }

  .sectionsContainer {
    flex-direction: column;
    gap: 40px;
  }

  .divider {
    margin-top: 40px;
  }
}
