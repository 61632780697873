.preferencesSection {
  background: rgba(25, 25, 25, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 968px;
  width: 100%;
  align-items: start;
  padding: 160px 70px 358px;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.contentContainer {
  position: relative;
  display: flex;
  margin-bottom: -72px;
  align-items: end;
  gap: 66px 19px;
}

.textContainer {
  display: flex;
  min-width: 240px;
  width: 582px;
  flex-direction: column;
}

.mainContent {
  display: flex;
  width: 100%;
  max-width: 727px;
  flex-direction: column;
}

.title {
  color: #fff;
  letter-spacing: -0.25px;
  font: 600 60px/64px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
}

.subtitle {
  color: #fff;
  letter-spacing: 0.1px;
  margin-top: 48px;
  font: 400 26px/26px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.storeButtonsContainer {
  display: flex;
  margin-top: 48px;
  gap: 48px;
  flex-wrap: wrap;
}

.storeButton {
  aspect-ratio: 3.34;
  object-fit: contain;
  object-position: center;
}

@media (max-width: 991px) {
  .contentWrapper {
    max-width: 100%;
    padding: 100px 20px;
  }

  .contentContainer {
    margin-bottom: 10px;
  }

  .mainContent {
    max-width: 100%;
  }

  .title {
    font-size: 40px;
    line-height: 47px;
  }

  .subtitle {
    margin-top: 40px;
  }

  .storeButtonsContainer {
    margin-top: 40px;
  }
}
