.navigationBar {
  background-color: rgba(25, 25, 25, 1);
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  padding: 22px 72px;
  font: 24px TT Commons, sans-serif;
}

.logo {
  aspect-ratio: 4.15;
  object-fit: contain;
  width: 237px;
}

.navContainer {
  display: flex;
  gap: 40px 100px;
  align-items: center;
}

.navMenu {
  display: flex;
  list-style: none;
  gap: 47px;
  margin: 0;
  padding: 0;
}

.navLink {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.downloadButton {
  align-self: stretch;
  font-family: Poppins, sans-serif;
  color: rgba(25, 25, 25, 1);
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 1;
  /* width: 227px; */
  margin: auto 0;
  border: none;
 
  background: none;
  cursor: pointer;
  padding: 0;
}

.buttonContent {
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 5.044;
  padding: 10px 23px;
}

.buttonIcon {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  object-position: center;
}


@media (max-width: 991px) {
  .navigationBar {
    padding: 0 20px;
  }

  .navContainer {
    gap: 20px;
  }
}
