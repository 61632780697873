.sellerSection {
  background-color: rgb(25, 25, 25);
  overflow: hidden;
}

.container {
  position: relative;
  min-height: 1525px;
  padding: 94px 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.content {
  position: relative;
  width: 1291px;
  max-width: 100%;
}

.heading {
  color: #fff;
  letter-spacing: -0.25px;
  font: 500 50px/1 Inter, sans-serif;
}

.description {
  margin-top: 30px;
  width: 1149px;
  max-width: 100%;
}

.subheading {
  color: #fff;
  letter-spacing: 0.1px;
  text-align: center;
  width: 678px;
  font: 600 24px/26px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.stepsContainer {
  margin-top: 271px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.stepContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.stepIndicator {
  position: relative;
  width: 30px;
  height: 30px;
}

.activeIndicator {
  background: linear-gradient(180deg, #e6e345 0%, #a1ee7d 100%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.inactiveIndicator {
  background-color: rgb(161, 162, 161);
  border-radius: 50%;
  width: 8px;
  height: 7px;
  margin-top: 10px;
}

.activeCenter {
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  width: 12px;
  height: 12px;
  right: 7px;
  bottom: 9px;
}

.stepContent {
  flex: 1;
}

.stepTitle {
  color: #fff;
  font: 600 18px/1.4 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.stepDescription {
  color: rgb(161, 162, 161);
  letter-spacing: 0.45px;
  margin-top: 7px;
  font: 400 14px/22px Karla, -apple-system, Roboto, Helvetica, sans-serif;
}

.actionButton {
  background: linear-gradient(180deg, #e6e345 0%, #a1ee7d 100%);
  border: none;
  border-radius: 8px;
  color: rgb(25, 25, 25);
  letter-spacing: 0.16px;
  margin: 297px 0 0 166px;
  padding: 14px 30px;
  font: 600 32px/1 "TT Commons", -apple-system, Roboto, Helvetica, sans-serif;
  cursor: pointer;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px 100px;
  }

  .heading {
    font-size: 40px;
  }

  .stepsContainer {
    margin-top: 40px;
  }

  .actionButton {
    margin: 40px 0 0 10px;
    padding: 0 20px;
  }
}
